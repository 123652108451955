<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>流量组</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/customerCard' }">
          客户名片
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="140px"
        size="mini"
      >
        <el-form-item
          class="input"
          label="昵称: "
          label-position="left"
          prop="nickname"
        >
          <el-input v-model="form.nickname" style="width: 200px"></el-input>
        </el-form-item>

        <el-form-item
          class="input"
          label="号码类型: "
          label-position="left"
          prop="contact_type"
        >
          <el-radio v-model="form.contact_type" :label="1">微信号</el-radio>
          <el-radio v-model="form.contact_type" :label="2">手机号</el-radio>
        </el-form-item>

        <el-form-item
          class="input"
          label="微信号/手机号: "
          label-position="left"
          prop="contact_number"
        >
          <el-input
            v-model="form.contact_number"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item class="input" label="来源: " label-position="left">
          <el-select
            v-model="form.source"
            class="search-select"
            clearable
            placeholder="请选择来源"
            size="mini"
          >
            <el-option label="小红书" value="1"> </el-option>
            <el-option label="抖音" value="2"> </el-option>
            <el-option label="头条" value="3"> </el-option>
            <el-option label="百度" value="4"> </el-option>
            <el-option label="微博" value="5"> </el-option>
            <el-option label="其他" value="6"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="input" label="咨询类型: " label-position="left">
          <el-select
            v-model="form.consult_type"
            class="search-select"
            clearable
            placeholder="请选择咨询类型"
            size="mini"
          >
            <el-option label="书本" value="1"> </el-option>
            <el-option label="课程" value="2"> </el-option>
            <el-option label="资料" value="3"> </el-option>
            <el-option label="其他" value="4"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注: " label-position="left">
          <el-input
            v-model="form.remark"
            style="width: 500px"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="图片: " label-position="left">
          <paste-image
            upload-path="sales"
            @onDelete="onDelete"
            @onSuccess="onSuccess"
          ></paste-image>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
          >
            保存
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.back()"
          >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PasteImage from "@/components/pasteImage/index";
export default {
  name: "Add",
  components: {
    PasteImage,
  },
  data() {
    return {
      loading: false,
      form: {
        pics: [],
      },
      rules: {
        nickname: [{ required: true, trigger: "blur", message: "请输入名称" }],
        contact_type: [
          { required: true, trigger: "change", message: "请选择号码类型" },
        ],
        contact_number: [
          { required: true, trigger: "blur", message: "请输入微信号/手机号" },
        ],
        source: [{ required: true, trigger: "change", message: "请选择来源" }],
        consult_type: [
          { required: true, trigger: "change", message: "请选择咨询类型" },
        ],
        remark: [{ required: true, trigger: "blur", message: "请输入备注" }],
        pics: [{ required: true, trigger: "change", message: "请选择图片" }],
      },
    };
  },
  methods: {
    ...mapActions("customerCard", ["addCustomer"]),
    onSuccess(pic) {
      this.form.pics = pic;
    },
    onDelete(pic) {
      this.form.pics = pic;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addClass();
        }
      });
    },
    async addClass() {
      this.loading = true;
      const { res_info } = await this.addCustomer(this.form);
      this.loading = false;
      this.$router.back();
      if (res_info != "ok") return;
      this.$message.success("创建成功");
    },
  },
};
</script>
